<template>
  <v-app>
    <Snackbar></Snackbar>
    <v-app-bar app color="primary" dark>
      <v-btn
        text
        v-if="activeRealm"
        @click="accountSelectionPage"
        ripple
        class="px-0"
        to="/home"
      >
        <v-avatar tile color="rgb(188,188,255)" size="32" class="rounded">
          <span class="primary--text">
            {{ activeRealm.id.substr(0, 2).toUpperCase() }}
          </span>
        </v-avatar>
        <span class="ml-2">{{ activeRealm.id.toUpperCase() }}</span>
      </v-btn>
      <v-spacer></v-spacer>
      <div
        class="active-email"
        v-if="customerEmail && $route.name == 'masterCustomer'"
      >
        <span class="header-text">Active Email: {{ customerEmail }}</span>
        <v-btn text @click="customerPage" ripple class="px-0">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-app-bar>
    <v-main>
      <v-container fluid style="padding-bottom: 4rem">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Snackbar from "../../components/Snackbar.vue";

export default {
  name: "partner",
  components: { Snackbar },
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
      isAccountsFetchWaiting: "partner/isFetchWaiting",
      activeAccount: "realm/active",
      customerEmail: "customerEmail",
      businessList: "business/list",
      activeRealm: "realm/active"
    }),
  },
  watch: {
    async activeAccount(val) {
      if (!val) {
        this.$router.replace("/select-account");
      } else {
        //await this.fetchAccountData(this.activeAccount);
      }
    },
  },
  methods: {
    ...mapActions({
      clearCustomer: "clearCustomer",
      setLogout: "user/logout",
    }),
    accountSelectionPage() {
      this.$router.push("/master/customer");
    },
    customerPage() {
      this.clearCustomer();
      this.$router.replace("/master/customer");
    },
    openChat(pageName) {
      // if (window.HelpCrunch) {
      //   window.HelpCrunch("updateUserData", {
      //     currentPage: pageName,
      //   });
      //   window.HelpCrunch("openChat");
      // }
      console.log(pageName);
    },
    partnerHome() {
      this.$router.replace("/partner/select-account");
    },
    logout() {
      this.setLogout()
      this.$router.push("/login");
    },
  },
  async created() {
    // Ensure data is fetched and data matches current selected account
    if (this.activeAccount) {
      // await this.fetchAccountData(this.activeAccount);
    }
  },
  beforeDestroy() {
    // this.clearAccountData();
  },
};
</script>

<style>
.loader-center {
  text-align: center;
  padding: 8rem 0;
}

.container--fluid {
  max-width: 1366px;
}

.filler {
  display: flex;
  flex: auto;
}

.header-text {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  transition-duration: 0.28s;
  transition-property: box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
</style>
